import { httpInstance_request, httpInstance_request_get } from "../request";
import { Notification } from "element-ui";

function DefaultHeadersParams() {
  return {
    "Content-Type": "application/json;charset=utf-8",
    accessToken: localStorage.getItem("accessToken"),
    appKey: "b2h4X29jZWFuc3RlbGxhcg==",
  };
}

function DefaultHeadersParams_refresh() {
  return {
    "Content-Type": "application/json;charset=utf-8",
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    appKey: "b2h4X29jZWFuc3RlbGxhcg==",
  };
}

function checkLoginParams(params) {
  if (params.username != "" && params.password != "") return true;
  else return false;
}

/**
 * @api login
 * params: {"username ":"xxxx","password":sha256(password)}
 */
export function login(params) {
  console.log("this is api/login");
  if (checkLoginParams(params)) {
    return httpInstance_request("/hsyq/user/login", {}, params); // login
  } else {
    Notification.error({
      title: "Error",
      message: "请输入正确的用户名和密码",
    });
  }
}

/**
 * @api logout
 * params = {"userId":"XXXX"}
 */
export function logout(params) {
  console.log("this is api/logout");
  return httpInstance_request("/hsyq/service/logout", DefaultHeadersParams_refresh(), params);
}

/**
 * @api refreshToken
 * params = {"refreshToken":refreshToken}
 */
export function refreshToken() {
  console.log("this is api/refreshToken");
  let params = {
    refreshToken: localStorage.getItem("refreshToken"),
  };
  return httpInstance_request("/hsyq/user/refresh", DefaultHeadersParams_refresh(), params);
}

/**
 * @api getUserInfo
 * params: {"userId ":"xxxxxx" }
 */
export function getUserInfo(params) {
  console.log("this is api/getUserInfo");
  // let tmp = localStorage.getItem("accessToken")
  // console.log(tmp)
  if (params.userId != "")
    return httpInstance_request_get("/hsyq/service/getUserInfo", DefaultHeadersParams(), params);
  else {
    console.log("getUserInfo失败：userId为空");
  }
}

/**
 * @api changePassword
 * params: {"userId ":"xxxxxx","password":sha256(password),"newPassword":sha256(newPassword)}
 */
export function changePassword(params) {
  console.log("this is api/changePassword");
  return httpInstance_request(
    "/hsyq/service/changePassword",
    DefaultHeadersParams_refresh(),
    params
  );
}

/**
 * @api getBoatorPortList
 * http://ip:port/hsyq/service/getBoatList?system_params
 *
 */
export function getBoatorPortList(code, params = {}) {
  console.log("this is api/getBoatorPortList");
  if (code == 1) {
    return httpInstance_request_get("/hsyq/service/getBoatList", DefaultHeadersParams(), params);
  } else {
    return httpInstance_request_get("/hsyq/service/getPortList", DefaultHeadersParams(), params);
  }
}

/**
 * @api getRelationNameList
 * http://ip:port/hsyq/service/getBoatListByPort?system_params
 *
 */
export function getRelationNameList(params = {}) {
  console.log("this is api/getRelationNameList");
  return httpInstance_request_get(
    "/hsyq/service/getRelationNameList",
    DefaultHeadersParams(),
    params
  );
}

/**
 * @api getBoatListByPort
 * http://ip:port/hsyq/service/getBoatListByPort?system_params&base64({"portName":"@portName"})
 *
 */
export function getBoatListByPort(params) {
  console.log("this is api/getBoatListByPort");
  return httpInstance_request_get(
    "/hsyq/service/getBoatListByPort",
    DefaultHeadersParams(),
    params
  );
}

/**
 * @api getRelationshipList 3.2.9
 * http://ip:port/hsyq/service/getRelationshipList?system_params&base64({
 *                                                                       "portName":"@portName",    //可选项，
 *                                                                       "boatName":"@boatName",    //可选项，
 *                                                                       "startTime":"20221020",    //必选
 *                                                                       "endTime":"20221020"       //必选
 *                                                                     })
 *
 */
export function getRelationshipList(params) {
  console.log("this is api/getRelationshipList");
  return httpInstance_request_get(
    "/hsyq/service/getRelationshipList",
    DefaultHeadersParams(),
    params
  );
}

/**
 * @api getTrackList 3.2.10
 * http://ip:port/hsyq/service/getTrackList?system_params&base64({
 *                                                                       "boatName":"@boatName",    //可选项，
 *                                                                       "startTime":"20221020",    //必选
 *                                                                       "endTime":"20221020"       //必选
 *                                                                     })
 *
 */
export function getTrackList(params) {
  console.log("this is api/getTrackList");
  return httpInstance_request_get("/hsyq/service/getTrackList", DefaultHeadersParams(), params);
}

/**
 * @api getBoatURL 3.2.11 获取船舶图片
 * http://ip:port/hsyq/service/getBoatURL?system_params&base64({
 *                                                                "boatKeyWord":"@mmsi",    //船舶关键字, 初步定义为船舶舷号
 *                                                                "pageSize":8,             //分页查询中，每页的数量，这个页是谁定义？
 *                                                                "pageNum": 1              //分页查询中，页面的编码，页码从1开始
 *                                                              })
 *
 */
export function getBoatURL(params) {
  console.log("this is api/getBoatURL");
  return httpInstance_request_get("/hsyq/service/getBoatURL", DefaultHeadersParams(), params);
}

/**
 * @api getMMSIByType 3.2.12 根据船舶类型返回MMSI列表
 * http://ip:port/hsyq/service/getMMSIByType?system_params&base64({"type":[0,1]})
 *
 */

export function getMMSIByType(params) {
  console.log("this is api/getMMSIByType");
  return httpInstance_request_get(
    "/hsyq/service/getMMSIByTypeAndScope",
    DefaultHeadersParams(),
    params
  );
}

/**
 * @api checkEmail
 * http://ip:port/hsyq/user/checkEmail?system_params&base64({"type":[0,1]})
 *
 */

export function checkEmail(params) {
  console.log("this is api/checkEmail");
  return httpInstance_request("/hsyq/user/checkEmail", DefaultHeadersParams(), params);
}

/**
 * @api sendEmailCode
 * http://ip:port/hsyq/user/sendEmailCode?system_params&base64({"type":[0,1]})
 *
 */

export function sendEmailCode(params) {
  console.log("this is api/sendEmailCode");
  return httpInstance_request_get("/hsyq/user/sendEmailCode", DefaultHeadersParams(), params);
}

/**
 * @api Register
 * http://ip:port/hsyq/user/Register?system_params&base64({"type":[0,1]})
 *
 */

export function Register(params) {
  console.log("this is api/register");
  return httpInstance_request("/hsyq/user/register", DefaultHeadersParams(), params);
}

/**
 * @api getTrackListByMMSI 3.2.16
 * http://ip:port/hsyq/service/getTrackListByMMSI?system_params&base64({
 *                                                                       "mmsi":"mmsi",    ，
 *                                                                       "startdt":"",
 *                                                                       "enddt":""
 *                                                                     })
 *
 */
export function getTrackListByMMSI(params) {
  console.log("this is api/getTrackListByMMSI");
  return httpInstance_request_get(
    "/hsyq/service/getTrackListByMMSI",
    DefaultHeadersParams(),
    params
  );
}

export function getMulPortRelationshipList(params) {
  console.log("this is api/getMulPortRelationshipList");
  console.log(params);
  return httpInstance_request_get(
    "/hsyq/service/getMulPortRelationshipList",
    DefaultHeadersParams(),
    params
  );
}
/**
 * @api getTrackListByMMSI 3.2.16
 * http://ip:port/hsyq/service/getTrackAisListByMMSI?system_params&base64({
 *                                                                       "mmsi":"mmsi",    ，
 *                                                                       "startdt":"",
 *                                                                       "enddt":"",
 *                                                                       "fid":""
 *                                                                     })
 *
 */
export function getTrackAisListByMMSI(params) {
  return httpInstance_request_get(
    "/hsyq/service/getTrackAisListByMMSI",
    DefaultHeadersParams(),
    params
  );
}
/**
 * @api getBoatListByPortName
 *
 *
 */
export function getBoatListByPortName(params) {
  return httpInstance_request_get(
    "/hsyq/service/getBoatListByPortName",
    DefaultHeadersParams(),
    params
  );
}

/**
 * @api getBoatListAll
 *
 *
 */
export function getBoatListAll(params) {
  return httpInstance_request_get("/hsyq/service/getBoatListAll", DefaultHeadersParams(), params);
}

/**
 * @api getBoatListAllByMMSI
 *
 *
 */
export function getBoatListAllByMMSI(params) {
  return httpInstance_request_get(
    "/hsyq/service/getBoatListAllByMMSI",
    DefaultHeadersParams(),
    params
  );
}

/**
 * @api getCommanderListByShip 根据船舶关键字获取指挥员列表
 */

export function getCommanderListByShip(params) {
  return httpInstance_request_get(
    "/hsyq/service/getCommanderListByShip",
    DefaultHeadersParams(),
    params
  );
}

/**
 * @api getCommanderInfoById 根据id获取指挥员信息
 */

export function getCommanderInfoById(params) {
  return httpInstance_request_get(
    "/hsyq/service/getCommanderInfoById",
    DefaultHeadersParams(),
    params
  );
}
/**
 *
 * @api getShipInfo 获取船舶信息接口
 */
export function getShipInfo(params) {
  return httpInstance_request_get("hsyq/service/getShipInfo", DefaultHeadersParams(), params);
}

/**
 * @api getRegionalAis 获取区域中AIS信息
 */
export function getRegionalAis(params) {
  return httpInstance_request_get("hsyq/service/getRegionalAis", DefaultHeadersParams(), params);
}

/**
 * @api getRegionalAisWithAnalysis 获取区域中AIS信息并分类
 */
export function getRegionalAisWithAnalysis(params) {
  return httpInstance_request_get(
    "hsyq/service/getRegionalAisWithAnalysis",
    DefaultHeadersParams(),
    params
  );
}

/**
 *
 * @api getAllPortList 获取所有港口列表
 */
export function getAllPortList(params) {
  return httpInstance_request_get("hsyq/service/getAllPortList", DefaultHeadersParams(), {});
}

/**
 *
 * @api getAisData 获取所有点位列表
 */
export function getAisData(params) {
  return httpInstance_request_get("hsyq/service/getAisData", DefaultHeadersParams(), {});
}

/**
 *
 * @api getAisDataByBound 获取区域内点位
 */
export function getAisDataByBoundSmall(params) {
  return httpInstance_request_get(
    "hsyq/service/getAisDataByBoundSmall",
    DefaultHeadersParams(),
    params
  );
}

/**
 *
 * @api getAisDataByBound 获取区域内点位
 */
export function getAisDataByBound(params) {
  return httpInstance_request_get("hsyq/service/getAisDataByBound", DefaultHeadersParams(), params);
}

/**
 *
 * @api getAisData 获取所有点位列表
 */
export function getAisDataLimit(params) {
  return httpInstance_request_get("hsyq/service/getAisDataLimit", DefaultHeadersParams(), {});
}
/**
 *
 * @api getAisShipByBoundPoint 获取区域内点位Bomao
 */
export function getAisShipByBoundPoint(params) {
  return httpInstance_request_get("hsyq/service/getAisShipByBoundPoint", DefaultHeadersParams(), params);
}
/**
 *
 * @api getAisDataByBound 获取区域内点位Bomao
 */
export function getAisShipByBound(params) {
  return httpInstance_request_get("hsyq/service/getAisShipByBound", DefaultHeadersParams(), params);
}

/**
 *
 * @api getAisDataByBound 获取区域内点位Bomao
 */
export function getLocationBoat(params) {
  return httpInstance_request_get("/hsyq/service/getLocationBoat", DefaultHeadersParams(), params);
}

/**
 *
 * @api getAisDataByBound 获取船舶信息（包括xy坐标）
 */
export function getAISnowByMMSI(params) {
  return httpInstance_request_get("/hsyq/service/getAISnowByMMSI", DefaultHeadersParams(), params);
}

/**
 *
 * @api addAreaGroup 新建区域分组
 */
export function addAreaGroup(params) {
  console.log("sssss");
  return httpInstance_request("/hsyq/service/addNewRegionGroup", DefaultHeadersParams(), params);
}

/**
 *
 * @api addArea 新建区域
 */
export function addArea(params) {
  return httpInstance_request("/hsyq/service/addNewRegion", DefaultHeadersParams(), params);
}

/**
 *
 * @api getGroupByUserId 根据区域ID查询区域列表
 */
export function getGroupByUserId(params) {
  return httpInstance_request_get("/hsyq/service/getGroupByUserId", DefaultHeadersParams(), params);
}

export function getBoatByKeyWord(params) {
  return httpInstance_request_get("/hsyq/service/getBoatByKeyWord", DefaultHeadersParams(), params);
}

export function getRegionByGroupId(params){
    return httpInstance_request_get("/hsyq/service/getRegionByGroupId", DefaultHeadersParams(), params);
}

export function deleteGroupRegion(params) {
    return httpInstance_request("/hsyq/service/deleteGroupRegion", DefaultHeadersParams(), params);
}

export function deleteRegionById(params) {
    return httpInstance_request("/hsyq/service/deleteRegionById", DefaultHeadersParams(), params);
}

export function renameRegionGroup(params) {
    return httpInstance_request("/hsyq/service/renameRegionGroup", DefaultHeadersParams(), params);
}

export function updateRegionState(params) {
    return httpInstance_request("/hsyq/service/updateRegionState", DefaultHeadersParams(), params);
}

export function getRegionalShipByLocation(params){
    return httpInstance_request_get("/hsyq/service/getRegionalShip", DefaultHeadersParams(), params);
}

export function updateRegion(params) {
    return httpInstance_request("/hsyq/service/updateRegion", DefaultHeadersParams(), params);
}

export function getAisStatByIdWithShipInfo(params){
    return httpInstance_request_get("/hsyq/service/getAisStatByIdWithShipInfo", DefaultHeadersParams(), params);
}

export function getFleetsByUserId(params){
    return httpInstance_request_get("/hsyq/service/getFleetsByUserId", DefaultHeadersParams(), params);
}

export function addNewFleet(params) {
    return httpInstance_request("/hsyq/service/addNewFleet", DefaultHeadersParams(), params);
}

export function deleteFleetById(params){
    return httpInstance_request("/hsyq/service/deleteFleet", DefaultHeadersParams(), params);
}

export function addFleetShip(params){
    return httpInstance_request("/hsyq/service/addFleetShip", DefaultHeadersParams(), params);
}

export function getShipByFleetId(params){
    return httpInstance_request_get("/hsyq/service/getShipByFleetId", DefaultHeadersParams(), params);
}


export function updateFleetStatus(params){
    return httpInstance_request("/hsyq/service/updateFleetStatus", DefaultHeadersParams(), params);
}

export function deleteFleetShip(params){
    return httpInstance_request("/hsyq/service/deleteFleetShip", DefaultHeadersParams(), params);
}


export function getShipAisstat(params){
    return httpInstance_request_get("/hsyq/service/getShipAisStat", DefaultHeadersParams(), params);
}

export function renameFleet(params){
    return httpInstance_request("/hsyq/service/renameFleet", DefaultHeadersParams(), params);
}

export function addNewMissionGroup(params){
    return httpInstance_request("/hsyq/service/addNewMissionGroup", DefaultHeadersParams(), params);
}



export function renameMissionGroup(params){
    return httpInstance_request("/hsyq/service/renameMissionGroup", DefaultHeadersParams(), params);
}


export function renameMission(params){
    return httpInstance_request("/hsyq/service/renameMission", DefaultHeadersParams(), params);
}



export function getMissionGroupByUserId(params){
    return httpInstance_request_get("/hsyq/service/getMissionGroupByUserId", DefaultHeadersParams(), params);
}

export function getAllFlag(params){
    return httpInstance_request_get("/hsyq/service/getAllFlag", DefaultHeadersParams(), params);
}

export function getAllType(params){
    return httpInstance_request_get("/hsyq/service/getAllType", DefaultHeadersParams(), params);
}

export function addNewMission(params){
    return httpInstance_request("/hsyq/service/addNewMission", DefaultHeadersParams(), params);
}

export function getMissionByGroupId(params){
    return httpInstance_request_get("/hsyq/service/getMissionByGroupId", DefaultHeadersParams(), params);
}

export function getBoatInfoByMMSI(params){
    return httpInstance_request_get("/hsyq/service/getBoatInfoByMMSI", DefaultHeadersParams(), params);
}


export function deleteGroupMission(params){
    return httpInstance_request("/hsyq/service/deleteGroupMission", DefaultHeadersParams(), params);
}

export function deleteMissionById(params){
    return httpInstance_request("/hsyq/service/deleteMissionById", DefaultHeadersParams(), params);
}

export function updateMissionStatus(params){
    return httpInstance_request("/hsyq/service/updateMissionStatus", DefaultHeadersParams(), params);
}



export function getMissionAisStatByIdWithShipInfo(params){
    return httpInstance_request_get("/hsyq/service/getMissionAisStatByIdWithShipInfo", DefaultHeadersParams(), params);
}



export function getRegionInfoById(params){
    return httpInstance_request_get("/hsyq/service/getRegionInfoById", DefaultHeadersParams(), params);
}




export function getFleetWithId(params){
    return httpInstance_request_get("/hsyq/service/getFleetWithId", DefaultHeadersParams(), params);
}


export function updateFleetShow(params){
    return httpInstance_request("/hsyq/service/updateFleetShow", DefaultHeadersParams(), params);
}

export function updateRegionShow(params){
    return httpInstance_request("/hsyq/service/updateRegionShow", DefaultHeadersParams(), params);
}

export function addNewTip(params){
    return httpInstance_request("/hsyq/service/addNewTip", DefaultHeadersParams(), params);
}

export function getTipInfo(params){
    return httpInstance_request_get("/hsyq/service/getTipInfo", DefaultHeadersParams(), params);
}

export function getQrcodeTicket(params){
    return httpInstance_request_get("/hsyq/service/getQrcodeTicket", DefaultHeadersParams(), params)
}




export function getWcSubList(params){
    return httpInstance_request("/hsyq/service/getWcSubList", DefaultHeadersParams(), params);
}


export function deleteWcSub(params){
    return httpInstance_request("/hsyq/service/deleteWcSub", DefaultHeadersParams(), params);
}



export function aroudBoatList(params){
  return httpInstance_request_get("/hsyq/service/aroudBoat", DefaultHeadersParams(), params);
}





